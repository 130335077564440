var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "key",
      staticClass: "key",
      class: { active: _vm.activated },
      on: {
        touchstart: function($event) {
          $event.preventDefault()
          return _vm.onTouchStart($event)
        },
        touchend: function($event) {
          $event.preventDefault()
          return _vm.onTouchEnd($event)
        },
        mousedown: function($event) {
          $event.preventDefault()
          return _vm.onMouseDown($event)
        },
        mouseleave: function($event) {
          $event.preventDefault()
          return _vm.onMouseUp($event)
        },
        mouseup: function($event) {
          $event.preventDefault()
          return _vm.onMouseUp($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "label" },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.label) } }),
          _vm._v(" "),
          _vm.sublabel
            ? [
                _c("span", { staticClass: "or" }, [_vm._v("or")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.sublabel))])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.description
        ? _c("div", {
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.description) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }