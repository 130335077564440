var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view" },
    [
      _vm.isSplash
        ? [
            _c("div", { staticClass: "splash" }, [
              _c("div", { staticClass: "logo" }, [
                _c("img", {
                  attrs: { src: require("./assets/logo.gif").default }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "menu" }, [
                _c("div", { staticClass: "corner corner-tl" }),
                _vm._v(" "),
                _c("div", { staticClass: "corner corner-tr" }),
                _vm._v(" "),
                _c("div", { staticClass: "corner corner-bl" }),
                _vm._v(" "),
                _c("div", { staticClass: "corner corner-br" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "menu-item",
                    on: {
                      click: function($event) {
                        return _vm.onStartGame()
                      }
                    }
                  },
                  [_vm._v("대항해시대2 플레이")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "menu-item",
                    on: {
                      click: function($event) {
                        return _vm.onStartGame(
                          "mod_junghwa_v3.0",
                          null,
                          "CHENGHO.DAT"
                        )
                      }
                    }
                  },
                  [_vm._v("정화편(v3.0) 플레이")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "menu-item",
                    on: {
                      click: function($event) {
                        return _vm.onStartGame("mod_ernst_v1.11", "PLAY.BAT")
                      }
                    }
                  },
                  [_vm._v("에르네스트 모드(v1.11) 플레이")]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "menu" },
                [
                  _vm.isLoginDbx === null
                    ? [
                        _c(
                          "a",
                          { staticClass: "menu-item menu-item-with-icon" },
                          [
                            _c("img", {
                              attrs: {
                                src: require("./assets/icon-dropbox.svg")
                                  .default
                              }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("인증정보를 가져오는 중")])
                          ]
                        )
                      ]
                    : !_vm.isLoginDbx
                    ? [
                        _c(
                          "a",
                          {
                            staticClass: "menu-item menu-item-with-icon",
                            on: { click: _vm.onLoginDropbox }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("./assets/icon-dropbox.svg")
                                  .default
                              }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("로그인")])
                          ]
                        )
                      ]
                    : [
                        _c(
                          "a",
                          {
                            staticClass: "menu-item menu-item-with-icon",
                            on: { click: _vm.onLogoutDropbox }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("./assets/icon-dropbox.svg")
                                  .default
                              }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("로그아웃")])
                          ]
                        )
                      ]
                ],
                2
              )
            ])
          ]
        : [
            _c("Game", {
              attrs: {
                mod: _vm.gameMod || undefined,
                entry: _vm.gameEntry || undefined,
                save: _vm.gameSave || undefined,
                dbx: _vm.dbx
              }
            })
          ],
      _vm._v(" "),
      _c("div", { staticClass: "version" }, [
        _vm._v("v" + _vm._s(_vm.version))
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "github",
          attrs: {
            href: "https://github.com/wan2land/unchartedwater2",
            target: "_blank"
          }
        },
        [
          _c(
            "svg",
            {
              attrs: {
                version: "1.1",
                width: "16",
                height: "16",
                viewBox: "0 0 16 16"
              }
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  d:
                    "M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("span", [_vm._v("View on Github")])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }